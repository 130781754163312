.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  margin-top: 32px;
  display: flex;
}

.header img {
  margin: auto;
}

.container, .container-fluid {
  margin-top: 20px;
}

.alert-message {
  text-align: center;
  margin: 50px;
  padding: 20px;
  background-color: #f44336;
  color: white;
  font-size: 16px;
}

.main-content {
  min-height: calc(100vh - 120px);
}

.main-content.login {
  margin-top: 40px;
}

.wish-list-entries {
  margin-top: 40px;
}

.list-title {
  /*width: 350px;*/
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 200;
}

.list-title.category-name {
  font-weight: 600;
  font-size: 22px;
  color: gray;
  text-align: center;
}

.list-title .listItemAction {
  margin-left: 8px;
}

.iconButtonNoOutline:focus {
  outline: none;
}

.list-title .listItemAction:focus {
  outline: none;
}

.actionMenuIcon {
  margin-right: 8px;
}

.wish-list-description {
  margin-top: 30px;
  white-space: pre-line;
  text-align: center;
}

.list-title a, .list-title a:hover, .list-title a:visited, .list-title a:focus {
  color:gray;
  cursor: pointer;
}
.back a, .back a:hover, .back a:visited, .back a:focus {
  color:gray;
  cursor: pointer;
}

.MuiGrid-root.to-shop {
  margin-top: 8px;
}

.to-shop a, .to-shop a:hover, .to-shop a:visited, .to-shop a:focus {
  color: #89c7b4;
  cursor: pointer;
  font-weight: 500;
}

.wish-list-entry-buttons {
  white-space: nowrap;
  display: inline;
}

.wish-list {
  min-height: 40px;
  text-align: center;
}

.category-add {
  margin-top: 20px;
  padding: 20px;
}

.MuiGrid-item .category-title-wrapper {
  margin-top: 32px;
}

.category-icon {
  color: #89c7b4;
  margin-right: 8px;
}

.category-title {
  min-height: 30px;
  margin-top: 50px;
}

.category-title-span {
  font-weight: 600;
  font-size: 22px;
}

.category-title > svg {
  margin-right: 8px;
  color: #89c7b4;
}

.button-group {
  display: inline-block;
  padding-left: 20px;
}

.footer {
  height: 50px;
  padding-left: 15px;
  margin-top: 30px;
  text-align: center;
}

.footer svg {
  color: #89c7b4;
}

body #root {
  color: gray;
}

.filter {
  margin-top: 30px;
  font-size: 14px;
}

.filter input[type=radio] {
  margin-left: 16px;
  margin-right: 3px;
}

.only-for-me {
  margin-left: 8px;
}

.MuiFormControl-root.margin-top, .margin-top {
  margin-top: 8px;
}
